<template>
  <div class="circle-circle-container">
    <div class="container">
      <!-- <div class="circle">
        <span class="new">最新圈子</span>
        <span class="all"
          ><router-link to="/allcircle">全部圈子>></router-link></span
        >
      </div> -->
      <AllCard :cardList="cardList" @refresh="refreshList()"></AllCard>
    </div>
    <Side :sideList="sideList" title="热门圈子"></Side>
  </div>
</template>

<script>
import Side from "../components/Side.vue";
import AllCard from "../components/AllCard.vue";
export default {
  components: {
    AllCard,
    Side,
  },
  data() {
    return {
      sideList: [],
      cardList: [],
    };
  },
  created() {
    this.circleList();
    this.getHotCircleList();
  },
  methods: {
    refreshList() {
      this.circleList();
    },
    async circleList(data) {
      const resp = await this.$apis.circleServe.circleList(data);
      this.cardList = resp.data;
    },
    async getHotCircleList(data) {
      const resp = await this.$apis.circleServe.getHotCircleList(data);
      this.sideList = resp.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.circle-circle-container {
  display: flex;
  align-items: flex-start;
  margin-top: 29px;
  margin-bottom: 15px;
  .circle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 29px;
    margin-bottom: 15px;
    .new {
      font-size: 18px;
      color: #333333;
    }
    .all {
      font-size: 16px;
      color: #00a4ff;
      cursor: pointer;
    }
  }
  .container {
    width: 1040px;
    margin-right: 40px;
  }
}
</style>
