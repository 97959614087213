import { render, staticRenderFns } from "./AllCard.vue?vue&type=template&id=c145a99e&scoped=true&"
import script from "./AllCard.vue?vue&type=script&lang=js&"
export * from "./AllCard.vue?vue&type=script&lang=js&"
import style0 from "./AllCard.vue?vue&type=style&index=0&id=c145a99e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c145a99e",
  null
  
)

export default component.exports